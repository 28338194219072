<template>
    <v-row no-gutters>
        <v-col cols="12">
            <!-- hierarchical navigation -->
            <!-- <v-row justify="start" class="mt-2 mx-4">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                <p class="text-caption text-start">
                    <router-link :to="{ name: 'dashboard' }">Dashboard</router-link>
                </p>
                </v-col>
            </v-row> -->
            <!-- <v-row justify="center" class="py-5 mt-2">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                <h1 class="text-h6 font-weight-light text-center">My Profile</h1>
                </v-col>
            </v-row> -->
            <v-row justify="center" class="py-5">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                    <template v-if="user">
                    <v-alert type="warning" v-if="!user.is_registered_loginshield && !user.is_registered_loginfront">
                        Your profile security settings are incomplete. <router-link :to="{ name: 'profile-setup' }" class="white--text">Set up authentication.</router-link>
                    </v-alert>
                    <v-card elevation="2" class="my-6" v-if="user">
                        <v-app-bar :color="primaryColor" dark flat dense>
                            <v-app-bar-title>My profile</v-app-bar-title>
                            <v-spacer/>
                            <!-- <v-btn icon @click="refreshUserProfile">
                                <font-awesome-icon :icon="['far', 'sync-alt']" fixed-width/>
                            </v-btn> -->
                        </v-app-bar>
                        <v-card-text>
                            <p class="text-overline mb-0">Name</p>
                            <p class="mb-0 pb-0">
                                <!-- <span>{{ displayName }}</span> -->
                                <EditableText :value="user.name" @input="saveUserDisplayName" dense></EditableText>
                                <!-- <v-btn class="blue--text no-print" @click="dialogEditDisplayName = true" icon>
                                    <font-awesome-icon :icon="['fas', 'pencil-alt']" fixed-width size="1x"/>
                                </v-btn> -->
                            </p>
                            <p class="text-overline mb-0 mt-4">Email</p>
                            <p class="mb-0 pb-0">
                                <span>{{ user.email }}</span>
                                <!-- <v-btn class="blue--text no-print" @click="dialogEditDisplayName = true" icon>
                                    <font-awesome-icon :icon="['fas', 'pencil-alt']" fixed-width size="1x"/>
                                </v-btn> -->
                            </p>
                            <!--
                            <p class="text-overline mb-0 mt-8">Security</p>
                            <p class="mb-0 pb-0">
                                <router-link :to="{ name: 'profile-settings-session' }">See my session info</router-link>
                            </p>
                            <p class="mb-0 pb-0">
                                <router-link :to="{ name: 'profile-settings-authz' }">See my roles and permits</router-link>
                            </p>
                            <p class="mb-0 pb-0">
                                <router-link :to="{ name: 'profile-settings-authn-loginfront' }">Change my Unicorn Springs login settings</router-link>
                            </p>
                            -->
                            <!-- <p class="text-overline mb-0 mt-8">Other</p>
                            <p class="mb-0 pb-0">
                                <v-btn :style="primaryButtonStyle" @click="refreshUserProfile">Refresh</v-btn>
                            </p> -->
                        </v-card-text>
                    </v-card>
                    <v-expansion-panels class="mt-8 mb-6">
                        <v-expansion-panel>
                            <v-expansion-panel-header>
                                <span class="red--text text--darken-2"><font-awesome-icon :icon="['fas', 'exclamation-triangle']" class="mr-1"></font-awesome-icon> Delete profile</span>
                            </v-expansion-panel-header>
                            <v-expansion-panel-content>
                                <p class="mb-0 pb-0">
                                    <router-link :to="{ name: 'profile-delete', params: { brandprofile: this.$route.params.brandprofile } }">How to delete this profile</router-link>
                                </p>
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                    </v-expansion-panels>
                    <v-dialog v-model="dialogEditDisplayName" max-width="600">
                        <v-card elevation="4" class="pa-5">
                            <v-card-text class="text-h6 pa-0 pb-3">Edit display name</v-card-text>
                            <v-row justify="center" class="pt-3">
                                <v-col cols="12">
                                    <div class="font-weight-light text-body-1">
                                        <p>We will use this name to address you on our website and when we communicate by email, telephone, or letters.</p>
                                        <p>You can use your full name, or only your first name, or a nickname if you prefer.</p>
                                        <p>For example, "Sparky" or "Doctor Who".</p>
                                    </div>
                                </v-col>
                            </v-row>
                            <v-form @submit="editDisplayName" onSubmit="return false;" @keyup.enter.native="editDisplayName">
                                <v-text-field
                                    v-model=editableDisplayName
                                    ref="displayNameInput"
                                    label="Your display name"
                                    :rules="nameRules"
                                    outlined
                                ></v-text-field>
                                <v-card-actions>
                                    <v-row justify="center">
                                    </v-row>
                                </v-card-actions>
                            </v-form>
                            <v-row justify="center">
                                <v-btn elevation="4" :style="primaryButtonStyle" @click="editDisplayName" :disabled="!isEditDisplayNameFormComplete">
                                    Save
                                </v-btn>
                                <v-btn text class="grey--text" @click="dialogEditDisplayName = false">Cancel</v-btn>
                            </v-row>
                        </v-card>
                    </v-dialog>
                    <!-- <p class="text-overline mb-0 mt-8">Volumes</p>
                    <p class="mb-0 pb-0">
                        <router-link :to="{ name: 'user-search-volume', params: { userId: this.user.id } }">See all volumes</router-link>
                    </p> -->
                    </template>
                </v-col>
            </v-row>

            <!-- <v-row justify="center" class="py-5">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                    <v-card elevation="4" class="pa-5">
                        <v-card-text class="text-h6 text-center pa-0 pb-3">Name</v-card-text>
                        <v-divider class="mx-5 mb-5"></v-divider>
                        <v-row justify="center" class="pb-3">
                            <v-col cols="12">
                                <div class="font-weight-light text-body-1 text-center mx-5">
                                    <p>We will use this name to address you on our website and when we communicate by email, telephone, or letters.</p>
                                    <p>For example, "Sparky" or "Doctor Who"</p>
                                </div>
                            </v-col>
                        </v-row>
                        <v-row justify="center" class="mx-5">
                            <v-text-field
                                outlined
                                v-model=editableDisplayName
                                :color="primaryColor"
                                label="Your nickname, first name, or title"
                                :rules="nameRules"
                            ></v-text-field>
                        </v-row>
                        <v-row justify="center">
                            <v-btn elevation="4" :style="primaryButtonStyle" @click="editDisplayName" :disabled="!isIdentityNameComplete">
                                <font-awesome-icon icon="check" fixed-width/>
                                Save
                            </v-btn>
                        </v-row>
                    </v-card>
                </v-col>
            </v-row> -->
            <!-- <p>If you change this address we will send a verification email and you must click on the link in the email to confirm the change.</p> -->
            <!-- <v-row justify="center" class="py-5">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                    <v-card elevation="4" class="pa-5">
                        <v-card-text class="text-h6 text-center pa-0 pb-3">Email</v-card-text>
                        <v-divider class="mx-5 mb-5"></v-divider>
                        <v-row justify="center" class="pb-3">
                            <v-col cols="12">
                                <div class="font-weight-light text-body-1 text-center mx-5">
                                    <p>We will use this email address to send you electronic messages.</p>
                                </div>
                            </v-col>
                        </v-row>
                        <v-row justify="center" class="mx-5">
                            <v-text-field
                                outlined
                                v-model=editableEmail
                                :color="primaryColor"
                                label="Your email address"
                                :rules="emailRules"
                                readonly
                                disabled
                            ></v-text-field>
                        </v-row>
                    </v-card>
                </v-col>
            </v-row> -->
            <!-- <v-row justify="center" class="py-5">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                    <p class="text-overline mb-0">Other</p>
                    <p>
                        <router-link :to="{ path: '/user/delete', query: { acct: this.$route.query.acct } }">How to delete this profile</router-link>
                    </p>
                </v-col>
            </v-row> -->
        </v-col>
    </v-row>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import { isValidName, isValidEmail, isValidPhone } from '@/sdk/input';
import EditableText from '@/components/EditableText.vue';

export default {
    components: {
        EditableText,
    },
    data: () => ({
        dialogDeleteOrganization: false, // TODO: remove this, a customer can only maybe delete their own profile, not an organization
        // nameRules: [
        //     (v) => (typeof v === 'string' && v.length >= 1) || 'Must not be empty',
        // ],
        nameRules: [
            (v) => isValidName(v) || 'Enter your name',
        ],
        emailRules: [
            (v) => !v || isValidEmail(v) || 'Enter your email address',
        ],
        phoneRules: [
            (v) => !v || isValidPhone(v) || 'Enter your phone number',
        ],
        // snackbarIdentityNameUpdated: false,
        // snackbarIdentityEmailUpdated: false,
        dialogEditDisplayName: false,
        editableDisplayName: null,
        editableEmail: null,
    }),
    computed: {
        ...mapState({
            isAuthenticatedReady: (state) => state.isAuthenticatedReady,
            brandprofile: (state) => state.brandprofile,
            user: (state) => state.user,
            session: (state) => state.session,
        }),
        ...mapGetters({
            primaryColor: 'primaryColor',
            primaryButtonStyle: 'primaryButtonStyle',
        }),
        displayName() {
            // TODO: restore display_name instead of name?
            if (typeof this.user.name === 'string' && this.user.name.trim().length > 0) {
                return this.user.name;
            }
            return '(unknown)';
        },
        // name: {
        //     get() {
        //         if (this.user.name) {
        //             return this.user.name;
        //         }
        //         return '';
        //     },
        //     set(value) {
        //         this.$store.dispatch('editUser', { name: value });
        //     },
        // },
        // email: {
        //     get() {
        //         if (this.user.email) {
        //             return this.user.email;
        //         }
        //         return '';
        //     },
        //     set(value) {
        //         // TODO: need email verification first ... let server set it after user clicks link ... server should reject editUser { email } , it should make available a workflow for changing email that a client can initiate
        //         this.$store.dispatch('editUser', { email: value });
        //     },
        // },
        isEditDisplayNameFormComplete() {
            return this.user
            && this.editableDisplayName
            && this.editableDisplayName !== this.user.display_name;
        },
        // isIdentityNameComplete() {
        //     return this.user
        //     && this.editableDisplayName
        //     && this.editableDisplayName !== this.user.name;
        // },
        // isIdentityEmailComplete() {
        //     return this.user
        //     && this.editableEmail
        //     && this.editableEmail !== this.user.email;
        // },
    },
    watch: {
        isAuthenticatedReady(value, oldValue) {
            if (value && !oldValue) {
                this.init();
            }
        },
        dialogEditDisplayName(display) {
            if (display) {
                this.editableDisplayName = this.user.display_name;
                setTimeout(() => { this.$nextTick(() => this.$refs.displayNameInput.focus()); }, 1);
            }
        },
        // email(val) {
        //     this.editableEmail = val;
        // },
        dialogDeleteOrganization(val) {
            if (!val) {
                this.closeDialogDeleteOrganization();
            }
        },
    },
    methods: {
        init() {
            console.log('profile.vue: init');
        },
        async editDisplayName() {
            // try {
            //     this.$store.commit('loading', { editDisplayName: true });
            const isEdited = await this.$store.dispatch('editCurrentUser', { display_name: this.editableDisplayName });
            // this.name = this.editableDisplayName;
            // this.snackbarIdentityNameUpdated = true;
            if (isEdited) {
                await this.$store.dispatch('loadUser');
                this.dialogEditDisplayName = false;
                this.$bus.$emit('snackbar', { type: 'success', message: 'Updated name' });
            } else {
                this.$bus.$emit('snackbar', { type: 'error', message: 'Failed to update name' });
            }
            // } catch (err) {
            //     console.error('editDisplayName failed', err);
            //     this.$bus.$emit('snackbar', { type: 'error', message: 'Failed to update name' });
            // } finally {
            //     this.$store.commit('loading', { editDisplayName: false });
            // }
        },
        // editEmail() {
        //     this.email = this.editableEmail;
        //     this.snackbarIdentityEmailUpdated = true;
        // },
        async deleteOrganization() {
            this.$store.commit('loading', { deleteOrganization: true });
            const response = await this.$client.site(this.brandprofile).user(this.$store.state.session.userId).self.delete();
            if (response.isDeleted) {
                await this.$store.dispatch('logout');
                this.$router.push('/');
                console.log('organization.vue: deleteOrganization success: %o', response);
            } else {
                console.log('organization.vue: deleteOrganization failed: %o', response);
                // this.snackbarOrganizationDeleteFailed = true;
            }
            this.closeDialogDeleteOrganization();
            this.$store.commit('loading', { deleteOrganization: false });
        },
        closeDialogDeleteOrganization() {
            this.dialogDeleteOrganization = false;
        },
        async refreshUserProfile() {
            /*
            this.$store.commit('loading', { refreshUserProfile: true });
            const response = await this.$client.site(this.brandprofile).user(this.user.id).self.check({ item: 'profile' });
            console.log(`refreshUserProfile edited? ${response.isEdited}`);
            if (response.isEdited) {
                await this.$store.dispatch('refresh');
                this.$bus.$emit('snackbar', { type: 'success', headline: 'Refreshed profile' });
            } else {
                this.$bus.$emit('snackbar', { type: 'info', headline: 'No changes to profile' });
            }
            this.$store.commit('loading', { refreshUserProfile: false });
            */
        },
        async saveUserAttr(name, value) {
            try {
                this.error = false;
                this.$store.commit('loading', { saveUserAttr: true });
                const response = await this.$client.site(this.brandprofile).user(this.$store.state.session.userId).self.edit({ [name]: value });
                console.log(`saveUserAttr: response ${JSON.stringify(response)}`);
                if (response?.isEdited) {
                    // this.$store.commit('user', { ...this.user, [name]: value });
                    await this.$store.dispatch('loadUser');
                } else {
                    this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to edit profile' });
                }
            } catch (err) {
                console.error(`failed to edit profile attr [${name}]: ${JSON.stringify(value)}`, err);
                this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to edit profile' });
            } finally {
                this.$store.commit('loading', { saveUserAttr: false });
            }
        },
        async saveUserDisplayName(value) {
            await this.saveUserAttr('display_name', value);
        },
    },
    mounted() {
        // when user is looking at their profile, we de-select whatever account may have been selected because now they're in between accounts
        this.$store.commit('account', null);
        this.$nav.set('account_id', null);
        this.init();
    },
};
</script>
